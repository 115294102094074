<template>
  <div class="home">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">

          <ul class="nav nav-admin">
            <li class="active">
              <router-link to="/account/overview">
                <i class="material-icons">dashboard</i>
                <span>Übersicht</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/reservations">
                <i class="material-icons">today</i>
                <span>Reservierungen</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/payment_methods">
                <i class="material-icons">account_balance</i>
                <span>Bezahlmethoden</span>
              </router-link>
            </li>
            <li>
              <router-link to="/account/settings">
                <i class="material-icons">settings</i>
                <span>Einstellungen</span>
              </router-link>
            </li>
          </ul>

        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Übersicht</li>
            </ul>
          </header>

          <div class="row row-gutter-20">
            <div class="col-12">
              <h4>Persönliche Daten</h4>
              <p>{{ user.first_name }} {{ user.last_name }}</p>
              <p>{{ user.email }}</p>
              <p><router-link to="/account/settings" class="button button-grey button-small">Persönliche Daten ändern ›</router-link></p>
            </div>
            <div class="col-12">
              <h4>Zahlungsart</h4>
              <p>Keine</p>
              <p><router-link to="/account/payment_methods" class="button button-grey button-small">Bezahlmethoden verwalten ›</router-link></p>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'user',
  data () {
    return {
      errors: [],
      user: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.user = response.data.user;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
